import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeone-company',
  templateUrl: './homeone-company.component.html',
  styleUrls: ['./homeone-company.component.scss']
})
export class HomeoneCompanyComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  scrollTo(target:string){
    this.viewportScroller.scrollToAnchor(target);
}
}
