<!-- Start Company Area -->
<section class="company-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="company-img">
                    <!-- <div class="trusted">
                        <span>100%</span>
                        <h3>Trusted</h3>
                        <p>You can depend on us for top quality repairs</p>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-content ptb-100">
                    <span class="top-title subheader">Welcome to <strong>CloverSure</strong></span>
                    <h2>Home Repairs Made Easy</h2>
                    <p class="some-bold">CloverSure provides care and repair for your home from day one. It’s a challenge scheduling a trustworthy contractor,
                    and the quote is not always honest. Your time is as valuable as your home.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
                    <a (click)="scrollTo('contactUs')" class="default-btn">Find Out More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Company Area -->
