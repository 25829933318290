<!-- Start Why Choose Us Area -->
<section class="why-choose-us-area why-choose-us-area-three pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-us-content">
                    <span class="top-title">Why Choose Us</span>
                    <h2>We Are Award Winning Company</h2>
                    <p class="bold">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint cupiditate, dolorem odio quia mollitia deleniti at error ratione qui vero provident</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis aut dolore laborum quisquam doloribus ducimus maiores? Dolor autem, nihil ipsa excepturi aspernatur in nobis nostrum blanditiis, numquam hic alias sit.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <ul>
                                <li><i class="flaticon-tick"></i> 25 Years of Experience</li>
                                <li><i class="flaticon-tick"></i> Save You Money</li>
                                <li><i class="flaticon-tick"></i> A Fast & Easy Application</li>
                            </ul>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <ul>
                                <li><i class="flaticon-tick"></i> 24/7 Support</li>
                                <li><i class="flaticon-tick"></i> Clients Focused</li>
                                <li><i class="flaticon-tick"></i> Growing Your Business</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="choose-us-three">
                    <img src="assets/img/choose-us/choose-us-3.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->