import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homethree-company',
  templateUrl: './homethree-company.component.html',
  styleUrls: ['./homethree-company.component.scss']
})
export class HomethreeCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
