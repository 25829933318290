import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-get-a-quote',
    templateUrl: './homeone-get-a-quote.component.html',
    styleUrls: ['./homeone-get-a-quote.component.scss']
})
export class HomeoneGetAQuoteComponent implements OnInit {

    name: string;
    emailAddress: string;
    phoneNumber: string;


    constructor() { }

    ngOnInit(): void {
    }

    openEmail() {
        let body = `Hello, %0D%0A
        Name: ${this.name}%0D%0A
        Email: ${this.emailAddress}%0D%0A
        Phone: ${this.phoneNumber}%0D%0A%0D%0A

        --- Please enter your Message Body Here ---%0D%0A%0D%0A

        Form submitted from Cloversure.com%0D%0A
        `;
        window.location.href = `mailto:claims@dynamichomerepair.com?Subject=Cloversure Contact Us&Body=${body}&From=${this.emailAddress}`
    }
}
