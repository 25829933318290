<!-- Start Banner Area -->
<section class="banner-area banner-area-three">
    <div class="container">
        <div class="banner-content">
            <h1 class="wow fadeInUp" data-wow-delay="0.3s">We Give Opportunities at Maximum Low Cost</h1>
            <div class="banner-btn wow fadeInUp" data-wow-delay="0.6s">
                <a routerLink="/contact-us" class="default-btn">Contact Us</a>
            </div>
        </div>

        <div class="banner-img-3 wow fadeInUp" data-wow-delay="0.9s">
            <img src="assets/img/banner/banner-img-3.png" alt="Image">
        </div>
    </div>
</section>
<!-- End Banner Area -->