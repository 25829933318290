<!-- Start Why Choose Us Area -->
<section id="fileClaim" class="why-choose-us-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="choose-us-content ptb-100">
                    <span class="top-title subheader">File a Claim</span>
                    <h2>Already Covered?</h2>
                    <p class="white">We don’t factor in luck when it comes to taking care of your home. We work by
                        making sure your home is taken care of
                        today and when you need it most. At CloverSure - luck isn’t in our plan for you.</p>
                    <ul class="white">
                        <li><i class="flaticon-tick"></i> Team of Claims Experts</li>
                        <li><i class="flaticon-tick"></i> A Fast & Easy Claims Process</li>
                        <li><i class="flaticon-tick"></i> Save You Headache and Frustration</li>
                        <li><i class="flaticon-tick"></i> Connect to a Real Person, Real Fast</li>
                        <li><i class="flaticon-tick"></i> No Luck, Just Real Coverage</li>
                    </ul>
                    <a href="https://form.jotform.com/203276139551152" target="_blank" class="default-btn">File a Claim</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="why-choose-slider-content">
                    <div class="why-choose-us-slider">
                        <owl-carousel-o [options]="whyChooseUsSliderOptions">
                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-1"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-2"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-3"></div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <!-- <div class="success">
                        <span>100%</span>
                        <h3>Success Rate</h3>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->
