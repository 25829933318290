<!-- Start Testimonials Area -->
<section class="testimonials-area testimonials-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Feedback</span>
            <h2>What Our Members Says</h2>
        </div>

        <div class="testimonials">
            <div class="testimonials-slider-three">
                <owl-carousel-o [options]="testimonialsSliderOptions">
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/testimonials-1.jpg" alt="Image">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.”<i class="flaticon-right-quote"></i></p> 
                            <h3>Tammie King</h3>
                            <span>Founder</span>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/testimonials-2.jpg" alt="Image">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.”<i class="flaticon-right-quote"></i></p>
                            <h3>Juhon Anderson</h3>
                            <span>Manager</span>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <img src="assets/img/testimonials/testimonials-3.jpg" alt="Image">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.”<i class="flaticon-right-quote"></i></p>
                            <h3>Alex Dew</h3>
                            <span>Auto Manager</span>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- Eed Testimonials Area -->