<!-- Start Footer Area -->
<footer class="footer-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget single-bg">
                    <a routerLink="/" class="logo">
                        <img width="225"
                            src="https://dynamichomerepairstg.blob.core.windows.net/cloverture/logo-250x250.png"
                            alt="Image">
                    </a>
                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin-square"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <ul class="address">
                        <li>
                            <span>Phone:</span>
                            <a href="tel:+1 (866) 212 7992">+1 (866) 212 7992</a>
                        </li>
                        <li>
                            <span>Email:</span>
                            <a href="mailto:contact@CloverSure.com">contact@CloverSure.com</a>

                        </li>
                        <li class="location">
                            <span>Address:</span>
                            1237 Front Street, Conway AR 72032
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <h3>Inspections</h3>
                <div><a href="https://form.jotform.com/230325172154143" target="_blank">1st Year Inspection</a></div>
                <div><a href="https://form.jotform.com/240915938211052" target="_blank">Annual Inspection</a></div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Get in Touch</h3>
                    <p>Sign up to stay up to date on our products and hear all our latest news.</p>

                    <form class="newsletter-form">
                        <input type="email" class="form-control" [(ngModel)]="emailAddress"
                            placeholder="Enter email address" name="EMAIL" required autocomplete="off">
                        <button class="default-btn" type="submit" (click)="openEmail()">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area bg-color">
    <div class="container">
        <p>Copyright @2021 <strong>CloverSure</strong>.</p>
    </div>
</div>
<!-- End Copy Right Area -->

<!-- Start Log In Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="bx bx-x"></i>
            </button>

            <div class="modal-body">
                <div class="container">
                    <div class="contact-form-action">
                        <div class="account-title">
                            <h2>Log In to Your Account</h2>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Email or Phone</label>
                                        <input class="form-control" type="text" name="name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input class="form-control" type="password" name="password">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="login-action">
                                        <span class="log-rem">
                                            <input id="remember" type="checkbox">
                                            <label for="remember">Remember me!</label>
                                        </span>
                                        <span class="forgot-login">
                                            <a routerLink="/recover-password">Forgot your password?</a>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="default-btn" type="submit">Log In</button>
                                </div>
                                <div class="col-12">
                                    <p>Have an account? <a routerLink="/register">Registration Now!</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Log In Modal -->

<!-- Start Register Modal -->
<div class="modal fade" id="staticBackdrop-2" data-bs-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="bx bx-x"></i>
            </button>

            <div class="modal-body">
                <div class="container">
                    <div class="contact-form-action">
                        <div class="account-title">
                            <h2>Register Your Account</h2>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Full name</label>
                                        <input class="form-control" type="text" name="name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Email address</label>
                                        <input class="form-control" type="email" name="email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Mobile no.</label>
                                        <input class="form-control" type="text" name="Number">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input class="form-control" type="text" name="password">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="show">
                                        <input id="remember-1" type="checkbox">
                                        <label>Show password ?</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="default-btn register" type="submit">Register Now</button>
                                </div>
                                <div class="col-12">
                                    <p>Have an account? <a routerLink="/log-in">Login now!</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register Modal -->
