<!-- Start Get Quote Area -->
<section id="contactUs" class="get-quote-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="subheader">Contact Us</span>
            <h2>Need to Get a Hold of Us?</h2>
        </div>

        <div class="tab get-quote-tab">
            <ul class="tabs">
                <!-- <li>Life Insurance </li> -->
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <form class="get-quote-form">
                        <div class="we-area-title">
                            <h3>Contact Us</h3>
                        </div>

                        <div class="row">

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" [(ngModel)]="name" class="form-control" id="First-Name-1" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" [(ngModel)]="emailAddress" class="form-control" id="Email-1" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phone" [(ngModel)]="phoneNumber" class="form-control" id="Number-1" placeholder="Phone Number">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <button type="submit" class="default-btn" (click)="openEmail()">Contact Us</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tabs_item">
                    <form class="get-quote-form">
                        <div class="we-area-title">
                            <h3>Home Insurance Quote</h3>
                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="all-skill-bar">
                                    <div class="skill-bar" data-percentage="25%">
                                        <h4 class="progress-title-holder">
                                            <span class="progress-title">Leave of Protection:</span>
                                            <span class="usd">$180.00</span>
                                            <span class="progress-number-wrapper">
                                                <span class="progress-number-mark">
                                                    <span class="percent"></span>
                                                    <span class="down-arrow"></span>
                                                </span>
                                            </span>
                                        </h4>
                                        <div class="progress-content-outter">
                                            <div class="progress-content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <span class="from-title">Personal Information:</span>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select>
                                        <option value="1">Employment Status</option>
                                        <option value="2">Services Man</option>
                                        <option value="3">Management</option>
                                        <option value="4">Receiver</option>
                                        <option value="5">Cline</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select>
                                        <option value="1">Your Age</option>
                                        <option value="2">29</option>
                                        <option value="3">18</option>
                                        <option value="4">22</option>
                                        <option value="5">30</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <span class="from-title">Contact Details:</span>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="First-Name-1" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" id="Email-1" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <button type="submit" class="default-btn">Get a Quote</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tabs_item">
                    <form class="get-quote-form">
                        <div class="we-area-title">
                            <h3>Auto Insurance Quote</h3>
                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="all-skill-bar">
                                    <div class="skill-bar" data-percentage="25%">
                                        <h4 class="progress-title-holder">
                                            <span class="progress-title">Leave of Protection:</span>
                                            <span class="usd">$180.00</span>
                                            <span class="progress-number-wrapper">
                                                <span class="progress-number-mark">
                                                    <span class="percent"></span>
                                                    <span class="down-arrow"></span>
                                                </span>
                                            </span>
                                        </h4>
                                        <div class="progress-content-outter">
                                            <div class="progress-content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <span class="from-title">Personal Information:</span>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select>
                                        <option value="1">Employment Status</option>
                                        <option value="2">Services Man</option>
                                        <option value="3">Management</option>
                                        <option value="4">Receiver</option>
                                        <option value="5">Cline</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select>
                                        <option value="1">Your Age</option>
                                        <option value="2">29</option>
                                        <option value="3">18</option>
                                        <option value="4">22</option>
                                        <option value="5">30</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <span class="from-title">Contact Details:</span>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="First-Name-1" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" id="Email-1" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <button type="submit" class="default-btn">Get a Quote</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tabs_item">
                    <form class="get-quote-form">
                        <div class="we-area-title">
                            <h3>Travel Insurance Quote</h3>
                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="all-skill-bar">
                                    <div class="skill-bar" data-percentage="25%">
                                        <h4 class="progress-title-holder">
                                            <span class="progress-title">Leave of Protection:</span>
                                            <span class="usd">$180.00</span>
                                            <span class="progress-number-wrapper">
                                                <span class="progress-number-mark">
                                                    <span class="percent"></span>
                                                    <span class="down-arrow"></span>
                                                </span>
                                            </span>
                                        </h4>
                                        <div class="progress-content-outter">
                                            <div class="progress-content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <span class="from-title">Personal Information:</span>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select>
                                        <option value="1">Employment Status</option>
                                        <option value="2">Services Man</option>
                                        <option value="3">Management</option>
                                        <option value="4">Receiver</option>
                                        <option value="5">Cline</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <select>
                                        <option value="1">Your Age</option>
                                        <option value="2">29</option>
                                        <option value="3">18</option>
                                        <option value="4">22</option>
                                        <option value="5">30</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <span class="from-title">Contact Details:</span>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input [(ngModel)]="name" type="text" name="name" class="form-control" id="First-Name-1" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input [(ngModel)]="emailAddress" name="email" type="email" class="form-control" id="Email-1" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phoneNumber" [(ngModel)]="phoneNumber" class="form-control" id="Number-1" placeholder="Phone Number">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <button type="submit" class="default-btn" (click)="openEmail()">Get a Quote</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Get Quote Area -->
