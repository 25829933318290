import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recover-password-page',
  templateUrl: './recover-password-page.component.html',
  styleUrls: ['./recover-password-page.component.scss']
})
export class RecoverPasswordPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
