<!-- Start Featured Services Area -->
<section class="featured-services-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Featured Services</span>
            <h2>All The Services That Our Company Provides</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="//business-insurance">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-life-insurance-1"></i>
                        <h3><a routerLink="//business-insurance">Life Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="//business-insurance">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-home-insurance"></i>
                        <h3><a routerLink="//business-insurance">Home Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="//business-insurance">
                        <img src="assets/img/services/services-3.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-insurance"></i>
                        <h3><a routerLink="//business-insurance">Business Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="//business-insurance">
                        <img src="assets/img/services/services-4.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-property-insurance"></i>
                        <h3><a routerLink="//business-insurance">Property Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="//business-insurance">
                        <img src="assets/img/services/services-5.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-travel-insurance"></i>
                        <h3><a routerLink="//business-insurance">Travel Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="//business-insurance">
                        <img src="assets/img/services/services-6.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-car-insurance"></i>
                        <h3><a routerLink="//business-insurance">Auto Insurance</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->