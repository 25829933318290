<!-- Start Header Area -->
<header class="header-area">

    <!-- Start Top Header -->

    <!-- Start Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="mobile-nav">
            <div class="container-fluid">
                <div class="mobile-menu">
                    <div class="logo ">
                        <a><img class="m-2"
                                src="https://dynamichomerepairstg.blob.core.windows.net/cloverture/logo-250x250.png"
                                alt="logo"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="desktop-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand py-2" routerLink="/"><img width="150px"
                            src="https://dynamichomerepairstg.blob.core.windows.net/cloverture/logo-250x250.png"
                            alt="logo"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav m-auto">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Home </a>
                            </li>

                            <li class="nav-item">
                                <a (click)="scrollTo('whyUs')" class="nav-link">Why Us</a>
                            </li>

                            <li class="nav-item">
                                <a (click)="scrollTo('whatsCovered')" class="nav-link">What's Covered</a>
                            </li>

                            <li class="nav-item">
                                <a (click)="scrollTo('fileClaim')" class="nav-link">File A Claim</a>
                            </li>

                            <li class="nav-item"><a (click)="scrollTo('contactUs')" class="nav-link">Contact Us</a></li>
                        </ul>

                        <div class="others-option">
                            <!-- <form class="search-box">
                                <input type="text" name="Search" placeholder="Search for..." class="form-control">
                                <button type="submit" class="search-btn"><i class="bx bx-search"></i></button>
                                <button type="submit" class="close-btn"><i class="bx bx-x"></i></button>
                            </form> -->

                            <div class="call-us">
                                <i class="bx bx-phone-call"></i>
                                <a href="tel:+1-866-212-7992">+1 (866) 212 7992</a>
                            </div>

                            <div class="get-quote">
                                <a (click)="scrollTo('contactUs')" class="default-btn cursor-pointer">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>

                <div class="container">
                    <div class="option-inner">
                        <div class="others-option justify-content-center d-flex align-items-center">
                            <div class="call-us">
                                <i class="bx bx-phone-call"></i>
                                <a href="tel:+1-866-212-7992">+1 (866) 212 7992</a>
                            </div>

                            <div class="get-quote">
                                <a (click)="scrollTo('contactUs')" class="default-btn">Get a Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->

</header>
<!-- End Header Area -->
