<!-- Start Page Title Area -->
<div class="page-title-area bg-12">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Right Sidebar</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Blog Right Sidebar</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-post">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-7.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="blog-content">
                                <ul>
                                    <li><i class="flaticon-user"></i> <a routerLink="/blog-right-sidebar">Jackson</a></li>
                                    <li><i class="flaticon-calendar"></i> October 27, 2020</li>
                                </ul>
                                <h3><a routerLink="/blog-details">Why Life Insurance Is a Force For Social Good</a></h3>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                                <a routerLink="/blog-right-sidebar" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-post">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-8.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="blog-content">
                                <ul>
                                    <li><i class="flaticon-user"></i> <a routerLink="/blog-right-sidebar">Peterson</a></li>
                                    <li><i class="flaticon-calendar"></i> October 28, 2020</li>
                                </ul>
                                <h3><a routerLink="/blog-details">5 Things to Know About Insurance Policy</a></h3>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-post">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-9.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="blog-content">
                                <ul>
                                    <li><i class="flaticon-user"></i> <a routerLink="/blog-right-sidebar">Wiliyams</a></li>
                                    <li><i class="flaticon-calendar"></i> October 29, 2020</li>
                                </ul>
                                <h3><a routerLink="/blog-details">Global Trends in The Home Insurance Industry</a></h3>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-post">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-10.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="blog-content">
                                <ul>
                                    <li><i class="flaticon-user"></i> <a routerLink="/blog-right-sidebar">Juhon</a></li>
                                    <li><i class="flaticon-calendar"></i> October 30, 2020</li>
                                </ul>
                                <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog-post">
                            <div class="blog-img">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/blog-11.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="blog-content">
                                <ul>
                                    <li><i class="flaticon-user"></i> <a routerLink="/blog-right-sidebar">Alex</a></li>
                                    <li><i class="flaticon-calendar"></i> October 29, 2020</li>
                                </ul>
                                <h3><a routerLink="/blog-details">Top Tips to Increase The Value of Your Home</a></h3>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim perspiciatis repellat nulla vero aliquid non quidem voluptatum minus ratione impedit inventore aut harum fugiat a dolore quas, atque eos blanditiis!</p>
                                <a routerLink="/blog-details" class="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog-right-sidebar" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">2</a>
                            <a routerLink="/blog-right-sidebar" class="page-numbers">3</a>
                            <a routerLink="/blog-right-sidebar" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Home Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Business Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Travel Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Home Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Auto Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Property Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Retirement Insurance</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget recent-post">
                        <h3 class="widget-title">Recent Post</h3>
                        
                        <ul>
                            <li>
                                <span>October 28, 2020</span>
                                <a routerLink="/blog-details">
                                    Insurance Industry Test Case Judgment
                                    <img src="assets/img/blog-details/recent-post-1.jpg" alt="Image">
                                </a>
                            </li>
                            <li>
                                <span>October 29, 2020</span>
                                <a routerLink="/blog-details">
                                    Why Life Insurance Is a Force For Social Good
                                    <img src="assets/img/blog-details/recent-post-2.jpg" alt="Image">
                                </a>
                            </li>
                            <li>
                                <span>October 30, 2020</span>
                                <a routerLink="/blog-details">
                                    5 Things to Know About Insurance Policy
                                    <img src="assets/img/blog-details/recent-post-3.jpg" alt="Image">
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>September <span>2</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>August <span>4</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>July <span>2</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>April <span>5</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>February <span>6</span></a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Meta</h3>

                        <ul>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> Log In</a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> Entries Feed</a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> Comments Feed</a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> WordPress.org</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags mb-0">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog-grid">Insurance</a></li>
                            <li><a routerLink="/blog-grid">Business</a></li>
                            <li><a routerLink="/blog-grid">Policy</a></li>
                            <li><a routerLink="/blog-grid">Health</a></li>
                            <li><a routerLink="/blog-grid">Auto</a></li>
                            <li><a routerLink="/blog-grid">Life</a></li>
                            <li><a routerLink="/blog-grid">Global</a></li>
                            <li><a routerLink="/blog-grid">Property</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->