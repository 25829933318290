<!-- Start Page Title Area -->
<div class="page-title-area bg-16">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-content">
                    <div class="blog-details-img">
                        <img src="assets/img/blog-details/blog-details-1.jpg" alt="Image">
                    </div>
                    <div class="blog-top-content">
                        <div class="news-content">
                            <ul class="admin">
                                <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Andrew Lawson</a></li>
                                <li><a routerLink="/blog-grid"><i class="bx bx-comment"></i> No comments</a></li>
                                <li class="float"><i class="bx bx-calendar-alt"></i> October 30, 2020</li>
                            </ul>
                            <h3>5 Things to Know About Insurance Policy</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur commodo.</p>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,sed quia consequuntur magni dolores eos qui ratione.</p>
                        </div>
                        <blockquote>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
                            <i class="bx bxs-quote-alt-right"></i>
                        </blockquote>
                        <div class="news-content-2">
                            <h3>Different Type of Insurances</h3>
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh. lectus. Lorem ipsum dolor sit amet, consectetuer</p>
                            <ul>
                                <li>At vero eos et accusam et justo duo dolores et ea rebum.</li>
                                <li>Stet clita kasd gubergren, no sea takimata sanctus est ipsum dolor sit amet.</li>
                                <li>Takimata sanctus est sed diam nonumy eirmod tempor invidunt</li>
                                <li>Labore et dolore magna aliquyam erat, sed diam voluptua.</li>
                                <li>Stet clita kasd gubergren, no sea takimata sanctus.</li>
                            </ul>
                        </div>
                        <div class="news-content-3">
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh. Lorem ipsum dolor sit amet, consectetuer</p>
                        </div>
                    </div>

                    <div class="tags-social">
                        <ul  class="tags">
                            <li><span>Tags:</span></li>
                            <li><a routerLink="/blog-grid" target="_blank">Insurance</a></li>
                            <li><a routerLink="/blog-grid" target="_blank">Business</a></li>
                            <li><a routerLink="/blog-grid" target="_blank">Policy</a></li>
                        </ul>
                        <ul class="social">
                            <li><span>Share:</span></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>

                    <div class="comments">
                        <h3>Comments (02)</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog-details/comments-1.jpg" alt="Image">
                                <h3>Janie Abram</h3>
                                <span>CEO & Founder</span>
                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>
                                <a routerLink="/"><i class="bx bxs-share"></i> Reply</a>
                            </li>

                            <li>
                                <img src="assets/img/blog-details/comments-2.jpg" alt="Image">
                                <h3>Thomas Brand</h3>
                                <span>Manager</span>
                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris.</p>
                                <a routerLink="/"><i class="bx bxs-share"></i> Reply</a>
                            </li>
                        </ul>
                    </div>

                    <div class="leave-reply">
                        <h3>Leave a Reply</h3>
                        <p>Your email address will not be published. Required fields are marked*</p>

                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Name*</label>
                                        <input type="text" name="name" id="name" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email address*</label>
                                        <input type="email" name="email" id="email" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Your website</label>
                                        <input type="text" name="your-website-link" id="your-website-link" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <textarea name="message" class="form-control" id="message" rows="8"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn page-btn">Post a Comment</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Home Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Business Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Travel Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Home Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Auto Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Property Insurance</a></li>
                            <li><a routerLink="/blog-details"><i class="flaticon-right-arrow"></i> Retirement Insurance</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget recent-post">
                        <h3 class="widget-title">Recent Post</h3>
                        
                        <ul>
                            <li>
                                <span>October 28, 2020</span>
                                <a routerLink="/blog-details">
                                    Insurance Industry Test Case Judgment
                                    <img src="assets/img/blog-details/recent-post-1.jpg" alt="Image">
                                </a>
                            </li>
                            <li>
                                <span>October 29, 2020</span>
                                <a routerLink="/blog-details">
                                    Why Life Insurance Is a Force For Social Good
                                    <img src="assets/img/blog-details/recent-post-2.jpg" alt="Image">
                                </a>
                            </li>
                            <li>
                                <span>October 30, 2020</span>
                                <a routerLink="/blog-details">
                                    5 Things to Know About Insurance Policy
                                    <img src="assets/img/blog-details/recent-post-3.jpg" alt="Image">
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>September <span>2</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>August <span>4</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>July <span>2</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>April <span>5</span></a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i>February <span>6</span></a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Meta</h3>

                        <ul>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> Log In</a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> Entries Feed</a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> Comments Feed</a></li>
                            <li><a routerLink="/blog-grid"><i class="flaticon-right-arrow"></i> WordPress.org</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags mb-0">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog-grid">Insurance</a></li>
                            <li><a routerLink="/blog-grid">Business</a></li>
                            <li><a routerLink="/blog-grid">Policy</a></li>
                            <li><a routerLink="/blog-grid">Health</a></li>
                            <li><a routerLink="/blog-grid">Auto</a></li>
                            <li><a routerLink="/blog-grid">Life</a></li>
                            <li><a routerLink="/blog-grid">Global</a></li>
                            <li><a routerLink="/blog-grid">Property</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->