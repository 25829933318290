<!-- Start Banner Area -->
<section class="banner-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="banner-content">
                            <span class="wow fadeInDown subheader" data-wow-delay="1s">Relax because you have more
                                <b>than luck</b> on your side – you’ve got CloverSure.</span>
                            <h1 class="wow fadeInDown" data-wow-delay="1s">CloverSure Home Warranty</h1>
                            <p class="wow fadeInLeft" data-wow-delay="1s">We know that owning a home might feel like
                                there are a lot of unknowns and that things may seem a bit unpredictable. But
                                at CloverSure we don’t rely on luck we plan for the future. We take care of you and your
                                home – now and when you’ll need
                                us most. So, relax because you have more than luck on your side – you’ve got CloverSure.
                            </p>

                            <div class="d-flex">
                                <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                    <a href="https://form.jotform.com/203276139551152" target="_blank" class="default-btn">File a
                                        Claim</a>
                                    <!-- <a href="https://customers.dynamichomerepair.com" target="_blank"
                                        class="default-btn">File a Claim</a> -->
                                </div>
                                <div class="banner-btn wow fadeInUp mx-3 cursor-pointer" data-wow-delay="1s">
                                    <a (click)="scrollTo('contactUs')" class="default-btn">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="banner-img">
                            <img src="https://dynamichomerepairstg.blob.core.windows.net/cloverture/bike.jpg"
                                alt="Image">
                            <div class="banner-shape-1">
                                <img src="assets/img/banner/banner-shape-1.png" alt="Image">
                            </div>
                            <!-- <ul>
                                <li><a href="#" target="_blank">Facebook</a></li>
                                <li><a href="#" target="_blank">Instagram</a></li>
                                <li><a href="#" target="_blank">Twitter</a></li>
                                <li><a href="#" target="_blank">Linkedin</a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Banner Area -->
