<!-- Start Testimonials Area -->
<section class="testimonials-area testimonials-area-two pt-100">
    <div class="container">
        <div class="section-title">
            <span>Our Feedback</span>
            <h2>What Our Members Says</h2>
        </div>

        <div class="testimonials">
            <div class="testimonials-slider">
                <owl-carousel-o [options]="testimonialsSliderOptions">
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <div class="row">
                                <div class="col-lg-3 col-md-12">
                                    <div class="testimonials-img bg-1"></div>
                                </div>
                                <div class="col-lg-9 col-md-12">
                                    <div class="testimonials-content">
                                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverramaecenas accumsan lacus vel facilisis.” <i class="flaticon-right-quote"></i></p> 
                                        <h3>Tammie King</h3>
                                        <span>Founder</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <div class="row">
                                <div class="col-lg-3 col-md-12">
                                    <div class="testimonials-img bg-2"></div>
                                </div>
                                <div class="col-lg-9 col-md-12">
                                    <div class="testimonials-content">
                                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverramaecenas accumsan lacus vel facilisis.” <i class="flaticon-right-quote"></i></p> 
                                        <h3>Juhon Anderson</h3>
                                        <span>Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials Area -->