<!-- Start Services Area -->
<section id="whatsCovered" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="subheader">What's Covered</span>
            <h2>The Vitals We Cover in Your Home.</h2>
        </div>
        <p class="section-title">We don’t factor in luck when it comes to taking care of your home. We work by making sure your home is taken care of
        today and when you need it most. At CloverSure - luck isn’t in our plan for you.</p>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <h3>Major Appliance</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-home-insurance"></i>
                    <h3>Plumbing</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-insurance"></i>
                    <h3>HVAC</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-property-insurance"></i>
                    <h3>Electrical</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a> -->
                </div>
            </div>



            <div class="col-lg-12 col-md-12">
                <a href="https://form.jotform.com/203276139551152" target="_blank" class="default-btn">File a Claim</a>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
