<app-homeone-banner></app-homeone-banner>

<!-- <app-partner></app-partner> -->

<app-homeone-future></app-homeone-future>

<app-homeone-company></app-homeone-company>

<app-homeone-services></app-homeone-services>

<app-homeone-why-choose-us></app-homeone-why-choose-us>

<app-homeone-get-a-quote></app-homeone-get-a-quote>

<!-- <app-homeone-feedback></app-homeone-feedback> -->

<!-- <app-team></app-team> -->

<!-- <app-homeone-faq></app-homeone-faq> -->

<!-- <app-blog></app-blog> -->
