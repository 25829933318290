<!-- Start Counter Area -->
<section class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <h2><span class="odometer" data-count="420">00</span></h2>
                    <p>Cases Completed</p>
                    <div class="counter-shape"><img src="assets/img/counter-shape.png" alt="Image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <h2>
                        <span class="odometer" data-count="1000">00</span> 
                        <span class="target">+</span>
                    </h2>
                    <p>Satisfied Clients</p>
                    <div class="counter-shape"><img src="assets/img/counter-shape.png" alt="Image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <h2><span class="odometer" data-count="654">00</span></h2>
                    <p>Insurance Policies</p>
                    <div class="counter-shape"><img src="assets/img/counter-shape.png" alt="Image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <h2>
                        <span class="odometer" data-count="100">00</span> 
                        <span class="target">%</span>
                    </h2>
                    <p>Success Rate</p>
                    <div class="counter-shape"><img src="assets/img/counter-shape.png" alt="Image"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter Area -->