<!-- Start Why Choose Us Area -->
<section class="why-choose-us-area why-choose-us-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="choose-us-content">
                    <span class="top-title">Why Choose Us</span>
                    <h2>We Are Award Winning Company</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint cupiditate, dolorem odio quia mollitia deleniti at error</p>
                    <ul>
                        <li><i class="flaticon-tick"></i> 25 Years of Experience</li>
                        <li><i class="flaticon-tick"></i> Save You Money</li>
                        <li><i class="flaticon-tick"></i> A Fast & Easy Application</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support</li>
                        <li><i class="flaticon-tick"></i> Growing Your Business</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="why-choose-us-img jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="choose-us-video">
                        <a href="https://www.youtube.com/watch?v=6TlMflQEpt8" class="popup-youtube">
                            <div class="video-button">
                                <i class="bx bx-play"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->