<!-- Start Services Area -->
<section class="services-area services-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Featured Services</span>
            <h2>All The Services That Our Company Provides</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-life-insurance-1"></i>
                    <h3>Life Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-home-insurance"></i>
                    <h3>Home Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-insurance"></i>
                    <h3>Business Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-property-insurance"></i>
                    <h3>Property Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-travel-insurance"></i>
                    <h3>Travel Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-car-insurance"></i>
                    <h3>Auto Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->