<!-- Start Future Area -->
<section id="whyUs" class="future-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span></span>
            <h2>Why Us</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-trustworthy"></i>
                    <h3>Trustworthy Company</h3>
                    <p>A company can't grow unless they are trusted by their community they service. In just 7 years
                        we've grown and gained the
                        trust of homeowners in over 4 states and through 9 dealerships.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-saving"></i>
                    <h3>Protect What Matters</h3>
                    <p>We know what your pain points are: HVAC, major appliances, electrical and plumbing. We cover your
                        home's vitals to make
                        sure you can rest easy.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-future-box">
                    <i class="flaticon-cross"></i>
                    <h3>Claims Experts</h3>
                    <p>Protect what matters with a team of experts helping you every step of the way. We are here for
                        you when you and your
                        home need us most. We always have your back.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Future Area -->
