<!-- Start Future Area -->
<section class="future-area future-area-two mt-minus-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="flaticon-trustworthy"></i>
                    <h3>Trustworthy Company</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box active">
                    <i class="flaticon-saving"></i>
                    <h3>Income Protection</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-future-box">
                    <i class="flaticon-cross"></i>
                    <h3>Anytime Cancellation</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Future Area -->