import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    emailAddress: string;

    constructor(private location: Location) { }

    ngOnInit(): void {
    }

    openEmail() {
        window.location.href=`mailto:claims@dynamichomerepair.com?Subject=Subcribe me to Cloversure news&Body=Please subscribe me to Cloversure news.&From=${this.emailAddress}`
    }
}
